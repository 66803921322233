
const buttons = document.querySelectorAll('.gallery__info-btn');


buttons.forEach(button => {
    button.addEventListener('mouseenter', () => {      
        const galleryText = button.closest('.gallery__item').querySelector('.gallery__text');
        galleryText.style.clipPath = 'circle(150% at 100% 20%)';
    });

    button.addEventListener('mouseleave', () => {
        const galleryText = button.closest('.gallery__item').querySelector('.gallery__text');
        galleryText.style.clipPath = 'circle(0% at 100% 0%)';
    });
});
